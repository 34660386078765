.deposit-modal-div {
  width: 100%;
}
.deposit-modal-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}
.deposit-modal-input input {
  padding: 15px;
  width: 80%;
  height: 100%;
  border: none;
  font-size: 16px;
}
.deposit-modal-maxbutton button{
  padding: 15px;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  background-color: aquamarine;
  border: none;
}
.deposit-modal-div-balance {
  margin: 5px 0px;
  color: rgba(239, 239, 228, 0.5);

}
.deposit-modal-div-balance span{
color: #efefe4;
}
.deposit-modal-input input:focus,
.deposit-modal-input input:active {
 border: none; 
 outline: none; 
}
.error-message-container {
  /* min-height: 20px;  */
  margin-bottom: 10px;
  color:#ff1e56;
 }
 .modal-deposit-selected-time-container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
 }
 .modal-deposit-selected-time {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
 }
 .modal-deposit-selected-time-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
 }
 .modal-deposit-selected-time-title p {
  margin: 0px;

 }
 .event-bot-switch-left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: rgba(255, 255, 255, 0.5);

}
.event-bot-switch-left p {
 cursor: pointer;
 padding-bottom: 10px;
 border-bottom: 1px solid transparent;
}

.event-tab-selected {
  border-bottom: 1px solid #62E6D4 !important;
  color: #fff;
}
.stake-position-container {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.stake-position-container p {
  margin: 5px 0px;
}
.deposit-card-containers {
  background-color:rgba(255, 255, 255, 0.05);
  height: fit-content;
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding:  10px ;

}
.deposit-card-container-title-p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: 22px;
  
}